let self = {
    defaultParaArray(){
        return [
            {
                identifier:"reg5",
                name:"PH",
                unit:"",
                display:true
            },
            {
                identifier:"reg4",
                name:"ORP",
                unit:"mv",
                display:true
            },
            {
                identifier:"reg6",
                name:"余氯",
                unit:"mg/L",
                display:true
            },
            {
                identifier:"reg3",
                name:"温度",
                unit:"℃",
                display:true
            },
            {
                identifier:"reg7",
                name:"浊度",
                unit:"NTU",
                display:true
            },
            {
                identifier:"reg8",
                name:"尿素",
                unit:"mg/L",
                display:true
            },
            {
                identifier:"reg1001",
                name:"溶解氧",
                unit:"mg/L",
                display:true
            },
            {
                identifier:"reg1002",
                name:"电导率",
                unit:"μS/cm",
                display:true
            },
            {
                identifier:"reg1003",
                name:"流量",
                unit:"L/H",
                display:true
            },
            {
                identifier:"reg1004",
                name:"流速",
                unit:"M/H",
                display:true
            },
            {
                identifier:"reg1005",
                name:"换水率",
                unit:"%",
                display:true,
            },
            {
                identifier:"reg1006",
                name:"浸脚池余氯",
                unit:"mg/L",
                display:true
            },

        ]
    },
    defaultSwitchArray(){
        return [
            {
                name:"循环水泵1",
                display:true
            },
            {
                name:"循环水泵2",
                display:true
            },
            {
                name:"循环水泵3",
                display:true
            },
            {
                name:"循环水泵4",
                display:true
            },
            {
                name:"循环水泵5",
                display:true
            },
            {
                name:"加热恒温设备",
                display:true
            },
            {
                name:"紫外杀菌设备",
                display:true
            },
            {
                name:"澄清剂药泵",
                display:true
            },
            {
                name:"ORP加药泵",
                display:true
            },
            {
                name:"PH碱加药泵",
                display:true
            },
            {
                name:"PH酸加药泵",
                display:true
            },
            {
                name:"池壁灯光设备",
                display:true
            },
        ]
    },

    screenParaArray(){
        let array = self.defaultParaArray()
        array.forEach(item=>{
            item.value=""
            item.min=""
            item.max=""
        })
        return array
    }
}
export default self
