<template>

</template>

<script>
export default {
  data() {
    return {
      deviceId: this.$route.params.deviceId,
      getDataInterval: null,
      device: {},
      valueMap: {}
    }
  },
  created() {
    this.deviceId = this.$route.params.deviceId

    this.getDeviceInfo()

    this.getDataInterval = setInterval(() => {
      this.getDeviceData()
    }, 1500)
    this.getDeviceData()

  },
  destroyed() {
    clearInterval(this.getDataInterval)
  },

  methods: {
    getDeviceInfo() {
      this.$get(`web/iot/water/device/info/${this.deviceId}`).then(r => {
        this.device = r.data;
        if(this.onDeviceInfo){
          this.onDeviceInfo()
        }
      });
    },
    getDeviceData() {
      this.$get(`web/iot/device/data/${this.deviceId}`).then(suc => {
        this.valueMap = suc.data
      })
    },
    control(key, value) {
      this.controlItems({[key]:value})

      return
      // const hide = this.$message.loading('正在设置...', 5);
      // this.$post("web/iot/device/control", {
      //   deviceId: this.deviceId,
      //   key: key,
      //   value: value
      // }).then(suc => {
      //   this.valueMap = {
      //     ...this.valueMap,
      //     [key]:value
      //   }
      //   hide()
      // }).catch(err => {
      //   hide()
      // })
    },
    controlItems(items) {
      const hide = this.$message.loading('正在设置...', 5);
      this.$post(`web/iot/device/mult-control?deviceId=${this.deviceId}`, items,true).then(suc => {
        hide()
        this.valueMap = {
          ...this.valueMap,
          ...items
        }
      }).catch(err => {
        hide()
      })
    },
    controlDevice(items) {
      let key = Object.keys(items)[0]
      let value = items[key]
      this.control(key, value)
    },
  }

}
</script>

<style scoped>

</style>
