<template>
  <common-form
    ref="form"
    url="web/iot/water/threshold"
    title="告警区间设置"
    :items="items"
    :beforeRequest="beforeRequest"
    @success="$emit('success')">

    <template slot="range">


      <a-table :columns="paraColumns" :data-source="paramsArray" bordered size="middle" :pagination="false">
        <template slot="value" slot-scope="text, record, index">
          <a-input v-model="record.value"></a-input>
        </template>
        <template slot="min" slot-scope="text, record, index">
          <a-input v-model="record.min"></a-input>
        </template>
        <template slot="max" slot-scope="text, record, index">
          <a-input v-model="record.max"></a-input>
        </template>
      </a-table>

    </template>


  </common-form>
</template>
<script>
  export default {
    props:["id"],
    data() {
      return {
        edit: false,
        paraColumns: [
          {
            title: '参数',
            dataIndex: 'name',
            width:100
          },
          {
            title: '最小值',
            dataIndex: 'min',
            scopedSlots: {customRender: 'min'},
          }, {
            title: '最大值',
            dataIndex: 'max',
            scopedSlots: {customRender: 'max'},
          }
        ],
        paramsArray:[],
      }
    },
    computed: {
      items() {
        return [
          // {
          //   type: "radio-button",
          //   name: "水质标准",
          //   key: "type",
          //   options:[
          //     { name:"中国", value:0 },
          //     { name:"欧洲", value:1 },
          //     { name:"俄罗斯", value:2 },
          //     { name:"手动", value:3 },
          //   ]
          // },
          {
            type: "slot",
            name: "参数区间",
            slot: "range"
          }
        ]
      }
    },
    methods: {
      show() {
        this.$get(`web/iot/water/threshold/${this.id}`).then(suc=>{
          this.$refs.form.show(suc.data)
          this.paramsArray = JSON.parse(suc.data.thresholdJson)
        })
      },
      beforeRequest(model){
        return {
          ...model,
          deviceId:this.id,
          thresholdJson:JSON.stringify(this.paramsArray)
        }
      },

    }

  }
</script>
