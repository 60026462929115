<template>
    <div class="standard-item" v-bind:class="{'standard-item-selected':selected}">
        <div class="title">
            <img class="icon" mode="aspectFit" :src="icon"/>
            {{title}}
        </div>
        <div class="row">
            <div class="range">
                余氯：{{range[0]}} <br/>
                浊度：{{range[2]}}
            </div>
            <div class="range">
                PH：{{range[1]}} <br/>
                ORP：{{range[3]}}
            </div>

            <a-button size="small" type="primary" v-if="selected">正在使用</a-button>
            <a-button size="small" type="default" v-else @click="$emit('select')">点击启用</a-button>

        </div>
    </div>
</template>

<script>
    export default {
        props:["title","icon","range","selected"]
    }
</script>

<style lang="less" scoped>
    .standard-item{
        background: #F9F9F9;
        padding: 10px 15px;
        border-radius: 5px;
        border: 1px solid #EEE;

        .title{
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            margin: 5px;

            .icon{
                height: 20px;
                margin-right: 8px;
            }
        }

        .row{
            display: flex;
            margin-top: 10px;
            align-items: center;
            font-size: 14px;

            .range{
                flex: 1;
                line-height: 24px;
            }

        }
    }

    .standard-item-selected{
        border: 1px solid #0084FF;
    }

</style>
