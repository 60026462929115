<template>
    <div class="water-switch" v-bind:class="{'switch-on':value}">
        <a-spin :spinning="false">

            <a-dropdown :trigger="['click']">
                <a-menu slot="overlay" @click="changeMode">
                    <a-menu-item v-for="(mode,index) in modeArray" :key="index">{{mode}}</a-menu-item>
                </a-menu>
                <a-button style="margin-bottom: 20px" block> {{modeArray[modeSelection]}}<a-icon type="down" /> </a-button>
            </a-dropdown>

            <div class="row">
                <div class="name">{{model.name}}</div>
                <a-switch v-if="writable" :checked="value"></a-switch>

                <a-tag v-else-if="value" class="tag"  color="#0084FF" >加药中</a-tag>
                <a-tag v-else class="tag" color="#999">药泵就绪</a-tag>

            </div>

        </a-spin>
    </div>
</template>

<script>
    export default {
        props:["model","valueMap","writable"],
        computed:{
            modeArray(){
                return ["自动加药模式","间断加药模式","定时加药模式","手动加药模式","时间控制模式"]
            },
            value(){
                return parseInt(this.valueMap[this.model.identifierRead])>0
            },
            modeSelection(){
                return parseInt(this.valueMap[this.model.modeWrite])
            }
        },
        methods:{
            changeMode(e){
                let mode = e.key
                this.$emit("control",this.model.modeWrite,mode)
            }
        }
    }
</script>

<style lang="less" scoped>
.water-switch{
    background: #FFF;
    border: 1px solid #F0F0F0;
    padding: 15px 20px;

    .row{
        display: flex;
        align-items: center;
        justify-content: space-between;

        .name{
            font-size: 15px;
            font-weight: 500;
        }
    }

    .tag{
        margin: 0;
    }
}
    .switch-on{
        /*border: 1px solid #0084FF;*/
    }

</style>
