<template>
    <div class="action-item clickable" @click="$emit('click')">
        <img class="icon" :src="icon"/>
        <div class="name">{{name}}</div>
    </div>
</template>

<script>
    export default {
        props:["name","icon"]
    }
</script>

<style lang="less" scoped>
.action-item{
    display: flex;
    flex-direction: column;
    align-items: center;

    .icon{
        width: 30px;
        height: 30px;
        object-fit: contain;
        margin: 8px;
    }

    .name{
        font-size: 13px;
        font-weight: 500;
    }
}
</style>
