<template>
  <a-modal v-model="visible" title="水质标准设置" :destroyOnClose="true" :width="900"
           okText="完成">
    <div class="standard-items">
      <WaterStandardItem v-for="(item,index) in standardArray"
                         :title="item.title" :icon="item.icon" :range="item.range" :selected="index==value"
                         @select="onSelect(index)"
      ></WaterStandardItem>
    </div>

  </a-modal>
</template>
<script>
  import WaterStandardItem from "../_components/WaterStandardItem";
  export default {
    components:{WaterStandardItem},
    props:["id","value"],
    data() {
      return {
        visible:false,
        standardArray:[
          {
            title:"中国泳池规范标准",
            icon:require('@/assets/hillbo/ic_standard_china.png'),
            range:["0.30 - 1.00","7.00 - 7.80","0.01 - 1.00","650 - 750"],
          },
          {
            title:"欧盟泳池规范标准",
            icon:require('@/assets/hillbo/ic_standard_eu.png'),
            range:["1.50 - 2.00","7.20 - 7.80","0.01 - 4.00","750 - 850"],
          },
          {
            title:"俄罗斯泳池规范标准",
            icon:require('@/assets/hillbo/ic_standard_russia.png'),
            range:["0.30 - 0.60","7.20 - 7.60","0.01 - 3.00","600 - 700"],
          },
          {
            title:"手动设置规范标准",
            icon:require('@/assets/hillbo/ic_standard_manual.png'),
            range:["0.50 - 1.00","7.20 - 7.80","0.01 - 1.00","700 - 750"],
          }
        ],

      }
    },
    methods: {
      show() {
        this.visible=true
      },
      onSelect(index){
        this.$emit('change',index)
      }
    }

  }
</script>
<style lang="less" scoped>
  .standard-items{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 10px;
    grid-column-gap: 10px;
  }
</style>
