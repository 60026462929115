<template>

    <div class="water-para">
        <a-spin :spinning="false">
        <div class="name-row">
            <div class="name">{{model.name}}</div>
            <a-tag v-if="status==0" color="#00ff99" style="margin: 0;">正常</a-tag>
            <a-tag v-if="status==-1" color="#EEC900" style="margin: 0">偏低</a-tag>
            <a-tag v-if="status==1" color="#EEC900" style="margin: 0">偏高</a-tag>
<!--            <div class="status">{{status}}</div>-->
        </div>
        <div class="value-row">
            <div class="value">{{value}}</div>
            <div class="unit">{{model.unit}}</div>
        </div>
        <div class="range">{{range}}</div>
        </a-spin>
    </div>

</template>

<script>
    export default {
        props:["model","valueMap"],
        computed:{
            range(){
                if(!this.model.min && !this.model.max){
                    return "未设置范围"
                }
                return (this.model.min||"") + " ~ " + (this.model.max||"")
            },
            value(){
                return this.valueMap[this.model.identifier] || "--"
            },
            status(){
                let min = parseFloat(this.model.min)
                let max = parseFloat(this.model.max)
                let value = parseFloat(this.valueMap[this.model.identifier])
                if(value<min){
                    return -1
                }else if(value>max){
                    return 1
                }else{
                    return 0
                }
            }
        }
    }
</script>

<style lang="less" scoped>
.water-para{
    background: #FFF;
    border: 1px solid #F0F0F0;
    padding: 15px 20px;

    .name-row{
        display: flex;
        align-items: flex-start;

        .name{
            flex: 1;
            font-size: 15px;
            font-weight: 500;
        }

        .status{
            background: #CCC;
            padding: 2px 10px;
            font-size: 8%;
            border-radius: 5px;
        }
    }

    .value-row{
        display: flex;
        align-items: baseline;
        margin-bottom: 2px;

        .value{
            font-size: 38px;
            margin-right: 10px;
            color: #0084FF;
        }

        .unit{
            font-size: 16px;
        }
    }



    .range{
        text-align: center;
        background: #F8F8F8;
        border-radius: 5px;
        padding: 3px;
        font-size: 12px;
    }
}
</style>
