<template>
    <common-form
            ref="form"
            url="web/iot/device"
            :edit="edit"
            :items="items"
            :beforeRequest="beforeRequest"
            @success="$emit('success')"></common-form>
</template>

<script>

    export default {
        data() {
            return {
                edit: false,
                modelOptions:[
                  {
                    name: "ICS 1",
                    value: "ICS_1",
                  },
                  {
                    name: "ICS 2",
                    value: "ICS_2",
                  },
                  {
                    name: "ICS 3",
                    value: "ICS_3",
                  },
                  {
                    name: "ICS PRO",
                    value: "ICS_PRO",
                  },
                  {
                    name: "ICS MAX",
                    value: "ICS_MAX",
                  }
                ],
                vendorOptions:[]
            }
        },
        computed: {
            items() {
                let arr = [
                    {
                        type: "input",
                        name: "名称",
                        key: "nickname",
                        required:true
                    },
                    {
                        type: "input",
                        name: "设备编号",
                        key: "iotId",
                        required:true,
                    },
                    {
                        type: "select",
                        name: "设备型号",
                        key: "model",
                        options: this.modelOptions,
                        required:true
                    },
                    // {
                    //     type: "location",
                    //     name: "地址",
                    //     key: "location",
                    // },
                ]
                if(!this.edit){
                    // arr.push({
                    //     type: "select",
                    //     name: "授权账号",
                    //     key: "vendorId",
                    //     options: this.vendorOptions
                    // })
                }
                return arr
            }
        },
        methods: {
            show(model = {}) {
                this.edit = model.id > 0
                if(this.edit){
                    model.location = {
                        address:model.address,
                        latitude:model.latitude,
                        longitude:model.longitude
                    }
                }
                this.$refs.form.show(model)
            },

            beforeRequest(model){
                return {
                    ...model,
                    ...model.location
                }
            }
        }

    }
</script>
<style lang="less" scoped>
    .block {
        padding: 5px;
        background: #FFF;
        border-radius: 5px;
        border: 1px solid #F0F0F0;

        .item-container {
            width: 25%;
            float: left;
            padding: 5px;
        }

        .item {
            border-radius: 5px;
            background: #F8F8F8;
            padding: 5px 15px;
        }

    }
</style>
